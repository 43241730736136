import * as React from "react";
import { graphql } from "gatsby";
import TemplatePage from "../../../../components/pages/templates/TemplatePage";

const Template = ({ data }) => (
  <TemplatePage
    title="Universal Mobile UI Kit | Mobile App UI Kit | Uizard"
    category="Mobile App Templates"
    url="/templates/mobile-app-templates/universal-mobile-ui-kit/"
    metaDescription="The Uizard universal app UI kit is a modular front-end framework for developing fast and powerful app interfaces. Design your app UI now."
    description="
    h2:Uizard's universal mobile app UI kit
    <br/>
    Our universal app UI kit is a lightweight and modular front-end framework for developing fast and powerful app interfaces. Customize and create your own <a:https://uizard.io/wireframing/>UI wireframes</a> and prototypes rapidly for any use case or use our pre-built screens to expedite your build.
    <br/>
    h3:Mobile app UI design made easy
    <br/>
    App design has never been easier with our modular app UI kit. <a:https://uizard.io/prototyping/>Prototype rapidly</a> with pre-made screens and drag-and-drop components. Uizard also makes it super easy to work with your team; invite team members, leave comments, and design synchronously.
    <br/>
    h3:Simply open our app UI kit and start designing
    <br/>
    Sign up to Uizard, select the universal app UI kit and get your build underway. Our app UI kit for modular building is designed to support rapid design and iteration, regardless of what type of app you are crafting.
    "
    pages={[
      "All the pre-built screens you might need to build your app design from e-commerce screens, to finance, and profiles screens",
      "Easy customization so you can adapt, update, and tweak your designs to suit your own app design project",
    ]}
    projectCode="EwyO0AbP4dhmex47Ea55"
    img1={data.image1.childImageSharp}
    img1alt="mobile ui kit overview"
    img2={data.image2.childImageSharp}
    img2alt="mobile ui kit dashboard components"
    img3={data.image3.childImageSharp}
    img3alt="mobile ui kit ecommerce components"
    img4={data.image4.childImageSharp}
    img4alt="mobile ui kit profile components"
    img5={data.image5.childImageSharp}
    img5alt="mobile ui kit onboarding components"
  />
);

export default Template;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    image1: file(
      relativePath: { eq: "templates/mobile-ui-kit/mobile-ui-kit-cover.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image2: file(
      relativePath: {
        eq: "templates/mobile-ui-kit/mobile-ui-kit-dashboards.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image3: file(
      relativePath: { eq: "templates/mobile-ui-kit/mobile-ui-kit-ecoms.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image4: file(
      relativePath: { eq: "templates/mobile-ui-kit/mobile-ui-kit-profiles.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image5: file(
      relativePath: {
        eq: "templates/mobile-ui-kit/mobile-ui-kit-onboarding.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
